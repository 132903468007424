import React from 'react';
import _ from 'lodash';

export default function parseAuthorizeLabel (txt, click) {
  if (_.isEmpty(txt)) {
    return;
  }

  const split = txt.split(/{{privacyStatement:(.*)}}/);
  if (split.length < 2) {
    return txt;
  }
  const params = split[1].split('@');
  const href = params.length > 1 ?
    <a href={(params[1].startsWith('http') ? '' : 'https://') + params[1]} target='_blank' rel='noopener noreferrer'>{params[0]}</a> :
    <a href='./' onClick={click}>{params[0]}</a>

  return <React.Fragment>
    {split[0]}
    {href}
    {split[2]}
  </React.Fragment>;
};
