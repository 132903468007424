import React, {useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import formatPhone from '@nexios/phone-number-formatter';
import Input from '../Input';
import Validation from '../Validation';
import Button from '../Button';
import styled from 'styled-components';
import checkmark from '../OptIn/checkmark.png';

const Box = styled.div`
  padding: 16px;
  display: block;
  background-color: ${props => props.theme.optInButton.backgroundColor};

  button {
    margin-top: 21px;
  }
`;
const Label = styled.div`
  width: 100%;
  height: auto;
  line-height: 32px;
  font-family: ${props => props.theme.optInButton.fontFamily};
  font-size: ${props => props.theme.optInButton.fontSize};
  font-style: normal;
  letter-spacing: 0.6px;
  text-align: left;
  color: ${props => props.theme.optInButton.color};
  border-bottom: ${props => props.theme.optInButton.borderBottom};
  box-shadow: ${props => props.theme.optInButton.boxShadow};
  margin-bottom: 16px;
`;
const OptInDone = styled.div`
  font-family: ${props => props.theme.optInButton.fontFamily};
  font-size: 31px;
  color: #199A57;

  .checkmark {
    background: url(${checkmark}) no-repeat 0;
    width: 35px;
    height: 20px;
    display: inline-block;
  }
`;

function AnonOptIn({formContext, isOptInDone, saveOptIn}) {
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isTelephoneValid, setIsTelephoneValid] = useState(false);
  const [emailValidationMessage, setEmailValidationMessage] = useState('');
  const [telephoneValidationMessage, setTelephoneValidationMessage] = useState('');

  const isAnonymous = formContext.donationPageType === '12_donationAnon';
  const allowEmail = formContext.allowNewsletter;
  const allowSms = formContext.allowSmsOptIn;

  const validateEmail = () => {
    if (_.trim(email) === '') {
      setIsEmailValid(false);
      setEmailValidationMessage('Er is geen e-mailadres ingevuld.');
    } else if (!/^[-0-9a-zA-Z.+_]{1,63}@([-0-9a-zA-Z+_]+\.){1,63}[a-zA-Z]{1,63}$/.test(_.trim(email))) {
      setIsEmailValid(false);
      setEmailValidationMessage('Er is geen geldig e-mailadres ingevuld.');
    } else {
      setIsEmailValid(true);
      setEmailValidationMessage('');
    }
  };

  const validateTelephone = () => {
    const validationResult = formatPhone(telephone);
    const valid = _.trim(telephone) === '' || (validationResult.result === 'GREEN' && validationResult.lineType === 'Mobiel');
    if (valid) {
      setIsTelephoneValid(true);
      setTelephoneValidationMessage('');
    } else {
      setIsTelephoneValid(false);
      setTelephoneValidationMessage('Er is geen geldig 06-nummer ingevuld.');
    }
  };

  const submitOptIn = (e) => {
    e.preventDefault();
    e.stopPropagation();

    validateEmail();
    validateTelephone();

    const isEmailEmpty = _.trim(email) === '';
    const isTelephoneEmpty = _.trim(telephone) === '';

    if ((!allowEmail || (!isEmailEmpty && isEmailValid)) && (isTelephoneValid || isTelephoneEmpty)) {
      saveOptIn(formContext.proposition, formContext.pageVariantKey, email, telephone);
    }
  };

  if (isOptInDone) {
    return <OptInDone>
      <span className='checkmark' />Aangemeld
    </OptInDone>;
  }

  return <>
    {isAnonymous && (allowEmail || allowSms) && <Box>
      {allowEmail && <div>
        <Label>Ja, ik blijf graag per e-mail op de hoogte</Label>
        <Input
          name='email'
          setValue={value => setEmail(value)}
          validate={validateEmail}
          value={email}
          placeholder='Vul hier je e-mailadres in*'
          isValid={isEmailValid}
          hasErrors={emailValidationMessage !== ''}
        />
        <Validation errors={[emailValidationMessage]} />
      </div>}

      {allowSms && <div>
        <Label>Ja, ik ontvang ook graag een sms-alert bij nieuwe acties</Label>
        <Input
          name='telephone'
          setValue={value => setTelephone(value)}
          validate={validateTelephone}
          value={telephone}
          placeholder='Vul hier je 06-nummer in'
          isValid={isTelephoneValid}
          hasErrors={telephoneValidationMessage !== ''}
        />
        <Validation errors={[telephoneValidationMessage]} />
      </div>}
      <Button label={'Aanmelden'} onClick={submitOptIn} />
    </Box>}
  </>;
}

AnonOptIn.propTypes = {
  isOptInDone: PropTypes.bool,
  saveOptIn: PropTypes.func
};

export default AnonOptIn;
