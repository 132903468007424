import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import LayoutContainer from '../../components/LayoutContainer';
import Box from '../../components/Box';
import MainContainer from '../../components/MainContainer';
import Logo from '../../components/Logo';
import DisplayIban from '../../components/DisplayIban';
import SocialShareButton from '../../components/SocialShareButton';
import {getFormData} from '@nexios/frontend-forms';
import {gaStep4} from '../../googleAnalytics/step4';
import {sendRevenue} from '../../vwo/sendRevenue';
import {connect} from 'react-redux';
import parseText from '../../utils/parseText';
import {subscribeToOptIn} from '../../reducer';
import {Helmet} from 'react-helmet';
import useScrollToTop from '../../hooks/useScrollToTop';
import AnonOptIn from '../../components/AnonOptIn';

const Step4 = ({formContext, formData, gaStep4, sendRevenueToVwo, isOptInDone, saveOptIn, theme, isProductForm}) => {
  useScrollToTop();

  const [socialMediaTitle, setSocialMediaTitle] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [whatsappUrl, setWhatsappUrl] = useState('');
  const [twitterUrl, setTwitterUrl] = useState('');
  const [thankYouTitle, setThankYouTitle] = useState('');
  const [thankYouText, setThankYouText] = useState('');

  useEffect(() => {
    gaStep4(formContext);

    if (window.isVwoEnabled && !isProductForm) {
      sendRevenueToVwo(formContext);
    }

    const url = encodeURIComponent(document.querySelector('meta[property=\'og:url\']').getAttribute('content'));

    setSocialMediaTitle(encodeURIComponent(formContext.socialMediaTitle));
    setFacebookUrl(`https://www.facebook.com/sharer.php?u=${url}&t=${socialMediaTitle}`);
    setLinkedinUrl(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`);
    setWhatsappUrl(`https://wa.me/?text=${socialMediaTitle}%20${url}`);

    if (formContext.twitterTitle) {
      const twitterTitle = encodeURIComponent(formContext.twitterTitle);
      setTwitterUrl(`https://twitter.com/share?text=${twitterTitle}&url=${url}`);
    } else {
      setTwitterUrl(`https://twitter.com/share?text=${socialMediaTitle}&url=${url}`);
    }

    const isOneOff = formData.frequency === 'oneOff';
    const isDirectDebit = formData.paymentMethod === 'directDebit';

    let titleTemplate;
    let textTemplate;

    if (isOneOff) {
      if (isDirectDebit) {
        titleTemplate = formContext.oneOffThankYouTitle;
        textTemplate = formContext.oneOffThankYouTextHtml_txt;
      } else {
        titleTemplate = formContext.donationThankYouTitle;
        textTemplate = formContext.donationThankYouTextHtml_txt;
      }
    } else if (formContext.mandateType === 'temporary') {
      titleTemplate = formContext.temporaryThankYouTitle;
      textTemplate = formContext.temporaryThankYouTextHtml_txt;
    } else {
      titleTemplate = formContext.continuousThankYouTitle;
      textTemplate = formContext.continuousThankYouTextHtml_txt;
    }

    setThankYouTitle(parseText(formData, titleTemplate));
    setThankYouText(parseText({...formData, ...formContext}, textTemplate, theme));
  }, [gaStep4, formContext, isProductForm, socialMediaTitle, formData, theme, sendRevenueToVwo]);

  return <>
    <Helmet>
      <title>{formContext.thankYouBrowserTitle}</title>
    </Helmet>
    <MainContainer
      backgroundImageUrl={formContext.backgroundImageUrl}
      backgroundImageTabletUrl={formContext.backgroundImageTabletUrl}
      backgroundImageMobileUrl={formContext.backgroundImageMobileUrl}
    />
    <LayoutContainer
      position={formContext.textPosition}
      header={<div>
        <div style={{float: 'left', display: 'inline-block'}}>
          <Logo url={formContext.websiteUrl} />
        </div>
        <DisplayIban iban='NL08 INGB 0000 0005 55' />
      </div>}
      content={<><Box>
        <div className='widgetContent'>
          <h1>{thankYouTitle}</h1>
          <div className='field'>
            <div dangerouslySetInnerHTML={{__html: thankYouText}} />
          </div>

          <AnonOptIn saveOptIn={saveOptIn} isOptInDone={isOptInDone} formContext={formContext} />

          {formContext.allowSocialShare && <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <SocialShareButton name='whatsapp' url={whatsappUrl} />
            <SocialShareButton name='facebook' url={facebookUrl} />
            <SocialShareButton name='linkedin' url={linkedinUrl} />
            <SocialShareButton name='twitter' url={twitterUrl} />
          </div>}
        </div>
      </Box>
        {formContext.allowCallToAction &&
          <div dangerouslySetInnerHTML={{__html: formContext.callToAction}} />}
      </>}
      footer={formContext.footer}
    />
  </>;
};

const mapStateToProps = (state) => {
  return {
    formContext: state.reducer.formContext,
    formData: getFormData(state, 'donate'),
    isOptInDone: state.reducer.isOptInDone,
    theme: state.reducer.theme,
    isProductForm: state.reducer.isProductForm
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  gaStep4: (formContext) => {
    dispatch(gaStep4(formContext));
  },
  sendRevenueToVwo: (formContext) => {
    dispatch(sendRevenue(formContext));
  },
  saveOptIn: (campaignPropositionCode, pageVariantKey, email, telephone) => {
    dispatch(
      subscribeToOptIn(
        campaignPropositionCode,
        pageVariantKey,
        email,
        telephone
      )
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Step4));

