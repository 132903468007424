import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {withRouter} from 'react-router-dom';
import BigNumber from 'bignumber.js';
import LayoutContainer from '../../components/LayoutContainer';
import Title from '../../components/Title';
import Box from '../../components/Box';
import {Form, Field, FormValidation} from '@nexios/frontend-forms';
import Button from '../../components/Button';
import MainContainer from '../../components/MainContainer';
import Logo from '../../components/Logo';
import Label from '../../components/Label';
import ProgressBar from '../../components/ProgressBar';
import parseAndGetBigNumber from '../../utils/parseAndGetBigNumber';
import ButtonContainer from '../../components/ButtonContainer';
import InputAmount from '../../components/InputAmount';
import FieldWrapper from '../../components/FieldWrapper';
import ErrorMessage from '../../components/ErrorMessage';
import {updateSession} from '../../reducer';
import {Helmet} from 'react-helmet';
import useScrollToTop from '../../hooks/useScrollToTop';
import Teaser from '../../components/Teaser';

const TaxDeductible1 = ({formContext, save, history}) => {
  useScrollToTop();
  const formConfig = {
    onSubmit: () => {
      save(['showCustomAmount', 'customAmount_m', 'amount_m'], () => {
        history.push(`/doneren${history.location.search}`);
      });
    },
    onDataChanged: (ctx) => {
      const frequency = formContext.frequencies.find(f => f.value === 'yearly');

      const theSelectedAmount = frequency.amounts.find(a => a.amount_m === ctx.data.amount_m);

      if (theSelectedAmount) {
        ctx.setValues({
          amountText: theSelectedAmount ? theSelectedAmount.message : null
        });
      } else {
        ctx.setValues({
          amountText: null
        });
      }

      ctx.validate(true);
    },
    onInit: (ctx) => {
      const frequency = formContext.frequencies.find(f => f.value === 'yearly');
      const showCustomAmount = _.has(ctx, 'showCustomAmount') ? ctx.showCustomAmount : false;
      const customAmount = showCustomAmount ? ctx.customAmount_m : null;
      ctx.setList('amounts', frequency.amounts.map(a => ({
        label: a.name,
        value: a.amount_m.toString()
      })));

      const amount = _.isNil(ctx.customAmount_m) ? ctx.amount_m || frequency.defaultAmount_m : null;
      const theSelectedAmount = frequency.amounts.find(a => a.amount_m === amount);

      ctx.setValues({
        showCustomAmount,
        allowCustomAmount: frequency.allowCustomAmount,
        amountText: showCustomAmount ? null : theSelectedAmount?.message || null,
        customAmount_m: customAmount,
        amount_m: amount
      });

    },
    fields: {
      allowCustomAmount: {
        isVisible: ctx => ctx.allowCustomAmount === true
      },
      customAmount_m: {
        isVisible: (ctx) => ctx.showCustomAmount === true,
        rules: [
          {
            isValid: ctx => {
              if (!ctx.showCustomAmount) {
                return true;
              }

              const frequency = formContext.frequencies.find(f => f.value === 'yearly');

              const maxAmount = BigNumber(frequency.maxAmount_m || '100000');
              const minAmount = BigNumber(frequency.minAmount_m || '1');

              const amountBn = parseAndGetBigNumber(ctx.customAmount_m);

              if (_.isNil(amountBn) || amountBn.isNaN()) {
                return true;
              }

              return amountBn.isGreaterThanOrEqualTo(minAmount) && amountBn.isLessThanOrEqualTo(maxAmount);
            },
            message: ctx => {
              const frequency = formContext.frequencies.find(f => f.value === 'yearly');

              const maxAmount = BigNumber(frequency.maxAmount_m || '100000');
              const minAmount = BigNumber(frequency.minAmount_m || '1');

              return `Vul een bedrag in tussen € ${minAmount.toFormat(2)} en € ${maxAmount.toFormat(2)}`;
            }
          },
          {
            isValid: ctx => {
              if (!ctx.showCustomAmount) {
                return true;
              }

              const amountBn = parseAndGetBigNumber(ctx.customAmount_m);
              return !_.isNil(amountBn) && !amountBn.isNaN();
            }
          }
        ]
      },
      amountText: {
        isVisible: (ctx) => ctx.showCustomAmount !== true
      },
      amount_m: {
        isVisible: (ctx) => {
          return ctx.showCustomAmount !== true;
        },
        rules: [
          {
            isValid: ctx => {
              if (ctx.showCustomAmount) return true;
              return !_.isNil(ctx.amount_m) && ctx.amount_m !== '';
            }
          }
        ]
      }
    }
  };

  return <>
    <Helmet>
      <title>{formContext.amountBrowserTitle}</title>
    </Helmet>
    <MainContainer
      backgroundImageUrl={formContext.backgroundImageUrl}
      backgroundImageTabletUrl={formContext.backgroundImageTabletUrl}
      backgroundImageMobileUrl={formContext.backgroundImageMobileUrl}
    />
    <LayoutContainer
      position={formContext.textPosition}
      header={<div>
        <div style={{float: 'left', display: 'inline-block'}}>
          <Logo url={formContext.websiteUrl} />
        </div>
      </div>}
      content={<Box>
        <Title title={formContext.step1Header}><ProgressBar progress='1' /></Title>
        <Form
          formInstanceKey='donate'
          formContext={formContext}
          formConfig={formConfig}>
          <FormValidation
            component={ErrorMessage}
            errorMessage='U heeft nog niet alle velden ingevuld.' />
          <div className='widgetContent'>

            <div className='marginBottom'>
              <Label label='Ik doneer per jaar' />
            </div>

            <Field
              list='amounts'
              name='amount_m'
              component={(props) => {
                return <div className='marginBottom amountButtons'>
                  <ButtonContainer
                    fieldName='amount_m'
                    valueName='amount_m'
                    list={props.list} /></div>;
              }}
            />

            <Field
              name='allowCustomAmount'
              component={() => <div className='marginBottom'>
                <Field
                  name='showCustomAmount'
                  component={({setValue, value, updateField}) => value ? null :
                    <div className='link center' onClick={() => {
                      setValue(true);
                      updateField('amount_m', null);
                    }}>
                      kies een ander bedrag
                    </div>}
                />
              </div>}
            />

            <div className='marginBottom'>
              <Field
                component={({value}) => <div className='center'>{value}</div>}
                name='amountText'
              />
            </div>

            <div className='marginBottom'>
              <Field
                name='customAmount_m'
                component={() => <div>Vul het bedrag in dat u wilt doneren</div>}
              />
              <Field
                name='customAmount_m'
                placeholder='€ 0,00'
                component={FieldWrapper}
                inputComponent={InputAmount}
              />
            </div>

            <div className='marginBottom'>
              <FormValidation
                type='submit'
                label={formContext.amountCallToActionLabel || 'Doneer'}
                component={Button}
              />
            </div>

            {formContext.entryCallToAction &&
              <div className='marginBottom centered'>
                {formContext.entryCallToActionUrl ? <a className='link' href={formContext.entryCallToActionUrl} rel='noopener noreferrer'>{formContext.entryCallToAction}</a> : formContext.entryCallToAction}
              </div>}
            <Field
              name='showCustomAmount'
              component={({setValue, value, updateField}) => value ?
                <div className='link' onClick={() => {
                  setValue(false);
                  updateField('customAmount_m', null);
                }}>Terug</div> : null}
            />
          </div>
        </Form>
      </Box>}
      sidebar={<Teaser header={formContext.pageTeaserHeader} body={formContext.pageTeaserBodyText} />}
      faq={formContext.frequentlyAskedQuestions}
      footer={formContext.footer}
    />

  </>;
};

const mapStateToProps = (state) => {
  return {
    formContext: state.reducer.formContext
  };
};

const mapDispatchToProps = (dispatch) => ({
  save: (fields, callback) => {
    dispatch(updateSession(fields, {
      isStep1Completed: true
    }, callback));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TaxDeductible1));
