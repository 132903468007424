import {withTheme} from 'styled-components';
import React from 'react';
import {withRouter} from 'react-router-dom';
import LayoutContainer from '../../components/LayoutContainer';
import Title from '../../components/Title';
import Box from '../../components/Box';
import Button from '../../components/Button';
import MainContainer from '../../components/MainContainer';
import Logo from '../../components/Logo';
import DisplayIban from '../../components/DisplayIban';
import ProgressBar from '../../components/ProgressBar';
import IBAN from 'iban';
import PaymentMethodButtonContainer from '../../components/PaymentMethodButtonContainer';
import Checkbox from '../../components/Checkbox';
import ModalDialog from '../../components/ModalDialog';
import _ from 'lodash';
import parseText from '../../utils/parseText';
import {renderDialog, closeDialog, save} from '../../reducer';
import {gaPaymentFailure} from '../../googleAnalytics/paymentFailure';
import {Field, Form, FormValidation} from '@nexios/frontend-forms';
import {isSepaCountryIban} from '@nexios/validation';
import {connect} from 'react-redux';
import ErrorMessage from '../../components/ErrorMessage';
import FieldWrapper from '../../components/FieldWrapper';
import Backbutton from '../../components/Backbutton';
import Dropdown from '../../components/Dropdown';
import Input from '../../components/Input';
import {Helmet} from 'react-helmet';
import parseAndGetBigNumber from '../../utils/parseAndGetBigNumber';
import parseAuthorizeLabel from '../../utils/parsePrivacyStatement';
import useScrollToTop from '../../hooks/useScrollToTop';
import Teaser from '../../components/Teaser';
import ProductImage from '../../components/ProductImage';
import CollectingSideBar from '../../components/CollectingSideBar';

const parseDirectDebitText = (ctx, {mandateType, directDebitOneOff, directDebitOther, directDebitTemporary}) => {
  const notOneOffText = mandateType === 'temporary' ? directDebitTemporary : directDebitOther;
  const txt = ctx.frequency === 'oneOff' ? directDebitOneOff : notOneOffText;
  return parseText(ctx, txt);
};

const Step3 = ({
                 formContext,
                 isDialogVisible,
                 renderDialog,
                 closeDialog,
                 save,
                 history,
                 gaPaymentFailure,
                 previous,
                 progressBar,
                 isProductForm,
                 theme
               }) => {
  useScrollToTop();
  const formConfig = {
    onSubmit: () => {
      save(history);
    },
    onInit: (ctx) => {
      ctx.setDefaultValues({
        donateAsOrganisation: false,
        optInAdminCostContribution: ctx.optInAdminCostContribution === true && ctx.frequency === 'oneOff'
      });

      // check if payment failed and we need to inform GA
      gaPaymentFailure();

      if (!isProductForm) {
        ctx.setList('frequencies', formContext.frequencies);
        const frequency = formContext.frequencies.find(f => f.value === ctx.frequency);
        ctx.setList('amounts', frequency.amounts.map(a => ({
          label: a.name,
          value: a.amount_m.toString()
        })));

        if (ctx.frequency === 'oneOff') {
          ctx.setValues({
            paymentMethod: formContext.paymentMethods[0].value
          });
        }

        if (ctx.frequency !== 'oneOff') {
          ctx.setValues({
            paymentMethod: 'directDebit'
          });
        }
      }

      ctx.setList('idealBanks', formContext.idealBanks);
      ctx.setList('creditCardBanks', formContext.creditCardBanks);
      ctx.setList('paymentMethods', formContext.paymentMethods);

      // set amountText on top of widget
      if (ctx.frequency === 'oneOff') {
        ctx.setValues({
          amountText: parseText(ctx, `Ik geef eenmalig €{{donationAmount}} ${ctx.optInAdminCostContribution === true ? '+ {{adminCostContribution}}' : ''}`)
        });
      } else if (formContext.mandateType === 'temporary') {
        ctx.setValues({
          amountText: parseText(ctx, 'Ik geef {{period}} lang, elke {{frequency}} €{{amount}}')
        });
      } else {
        ctx.setValues({
          amountText: parseText(ctx, 'Ik geef €{{amount}} per {{frequency}}')
        });
      }

      const authorizeLabel = ctx.donateAsOrganisation ? formContext.privacyConfirmationTextOrganisation : formContext.privacyConfirmationTextPerson;
      const directDebitText = parseDirectDebitText(ctx, formContext);

      ctx.setValues({
        step3Header: parseText(ctx, _.get(formContext, ['step3Header'])),
        authorizeLabel,
        directDebitText
      });

      if (!formContext.authorizeAsCheckbox) {
        ctx.setValues({
          authorize: true
        });
      }

      let orderSummary;
      const productAmount = _.toInteger(ctx.productAmount);
      const formattedAmount = parseAndGetBigNumber(ctx.amount_m || 0).toFormat(2);
      if (productAmount > 1) {
        orderSummary = `${productAmount} ${formContext.productNamePlural}: € ${formattedAmount}`;
      } else {
        orderSummary = `1 ${formContext.productName}: € ${formattedAmount}`;
      }

      ctx.setValues({
        orderSummary: orderSummary
      });

    },
    fields: {
      paymentMethod: {
        isVisible: ctx => ctx.frequency === 'oneOff' || isProductForm,
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.paymentMethod)
          }
        ]
      },
      directDebitText: {
        isVisible: ctx => ctx.paymentMethod === 'directDebit'
      },
      iban: {
        isVisible: ctx => ctx.paymentMethod === 'directDebit',
        isRequired: ctx => ctx.paymentMethod === 'directDebit',
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.iban) && ctx.iban !== ''
          },
          {
            message: 'Vul hier een geldig IBAN nummer in.',
            isValid: ctx => {
              var value = ctx.iban;

              if (_.isNil(value)) {
                return true;
              }

              return IBAN.isValid(value);
            }
          },
          {
            message: 'IBAN moet afkomstig zijn uit een SEPA land',
            isValid: ctx => {
              const value = ctx.iban;
              if (_.isNil(value) || value === '') return true;

              return isSepaCountryIban(value);
            }
          }
        ]
      },
      bank: {
        isVisible: ctx => ctx.paymentMethod === 'ideal' && !formContext.hideBankOptions,
        isRequired: ctx => ctx.paymentMethod === 'ideal' && !formContext.hideBankOptions,
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.bank) && ctx.bank !== ''
          }
        ]
      },
      creditCardBank: {
        isVisible: ctx => ctx.paymentMethod === 'creditCard',
        isRequired: ctx => ctx.paymentMethod === 'creditCard',
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.creditCardBank) && ctx.creditCardBank !== ''
          }
        ]
      },
      authorize: {
        isVisible: ctx => ctx.donationPageType !== '12_donationAnon',
        isRequired: ctx => ctx.donationPageType !== '12_donationAnon',
        rules: [
          {
            isValid: ctx => ctx.authorize === true
          }
        ]
      },
      allowNewsletter: {
        isVisible: () => formContext.allowNewsletter === true && formContext.allowNewsletterDisplay === 'step3' && formContext.donationPageType !== '12_donationAnon'
      },
      amountText: {
        isVisible: () => !isProductForm && theme.step3HideAmountText !== true
      },
      orderSummary: {
        isVisible: () => isProductForm
      }
    }
  };

  let themeHeader = formContext.donationPageType === '12_donationAnon' ? theme.step3WidgetHeaderTextNoNaw : theme.step3WidgetHeaderText;

  let content = <Box>
    <Title title={themeHeader || formContext.step3Header}><ProgressBar progress={progressBar} /></Title>
    <Form
      formInstanceKey='donate'
      formContext={formContext}
      formConfig={formConfig}>
      <FormValidation
        component={ErrorMessage}
        errorMessage='Je hebt nog niet alle velden ingevuld.' />
      <div className='widgetContent'>
        <Field
          component={({value}) => <div className='marginBottom'>{value}</div>}
          name='amountText' />
        <Field
          name='orderSummary'
          component={({value}) => {
            return (
              <div className='marginBottom'>
                <div>Je bestelling:<br />
                  {value}
                </div>
              </div>
            );
          }}
        />
        <Field
          name='paymentMethod'
          component={(props) => {
            return <div className='marginBottom'><PaymentMethodButtonContainer
              fieldName='paymentMethod'
              valueName='paymentMethod'
              list={props.list} /></div>;
          }}
          list='paymentMethods'
        />
        <Field
          list='idealBanks'
          name='bank'
          component={(props) => <div className='marginBottom'><FieldWrapper {...props} /></div>}
          placeholder={'Kies je bank'}
          inputComponent={Dropdown}
        />
        <Field
          list='creditCardBanks'
          name='creditCardBank'
          component={(props) => <div className='marginBottom'><FieldWrapper {...props} /></div>}
          placeholder={'Kies je creditcard'}
          inputComponent={Dropdown}
        />
        <Field
          label='Rekeningnummer'
          name='iban'
          component={(props) => <div className='marginBottom'><FieldWrapper {...props} /></div>}
          inputComponent={Input}
          placeholder='NL11ABNA012345678' />
        <Field
          component={({value}) => <div className='marginBottom'>{value}</div>}
          name='directDebitText' />
        <div className='marginBottom'>
          {formContext.authorizeAsCheckbox && <Field
            hideLabel
            name='authorize'
            component={FieldWrapper}
            inputComponent={Checkbox}
            label={<Field
              name='authorizeLabel'
              component={({value}) =>
                <span>{parseAuthorizeLabel(value, renderDialog)}</span>} />} />}

          {!formContext.authorizeAsCheckbox && <Field
            name='authorizeLabel'
            component={({value}) =>
              <span>{parseAuthorizeLabel(value, renderDialog)}</span>} />}
        </div>
        <div className='marginBottom'>
          <Field
            name='allowNewsletter'
            label={formContext.newsletterText || 'Houd mij op de hoogte over nieuwe ontwikkelingen.'}
            component={FieldWrapper}
            inputComponent={Checkbox}
            hideLabel
          />
        </div>
        <div className='marginBottom'>
          <Field
            name='paymentMethod'
            noAutoHide={true}
            component={({value}) => <FormValidation
              component={Button}
              label={value === 'directDebit' ? formContext.paymentMethodMandateCallToActionLabel || 'Doneer nu' : formContext.paymentMethodCallToActionLabel || 'Naar betalen'}
              type='submit'
            />} />
        </div>
        <div>
          <Backbutton link={`${previous}${history.location.search}`} />
        </div>
      </div>
    </Form>
  </Box>;

  if (isDialogVisible) {
    content = <ModalDialog close={() => {
      closeDialog();
    }}>
      <div dangerouslySetInnerHTML={{__html: formContext.privacyStatement}} />
    </ModalDialog>;
  }

  return <>
    <Helmet>
      <title>{formContext.paymentMethodBrowserTitle}</title>
    </Helmet>
    <MainContainer
      backgroundImageUrl={formContext.backgroundImageUrl}
      backgroundImageTabletUrl={formContext.backgroundImageTabletUrl}
      backgroundImageMobileUrl={formContext.backgroundImageMobileUrl}
    />
    <LayoutContainer
      position={formContext.textPosition}
      header={<div>
        <div style={{float: 'left', display: 'inline-block'}}>
          <Logo url={formContext.websiteUrl} />
        </div>
        <DisplayIban iban='NL08 INGB 0000 0005 55' />
      </div>}
      content={content}
      sidebar={
        <>
          {isProductForm && <ProductImage url={_.get(formContext, ['productImageUrl'])} collapseIfEmpty />}
          {formContext.isCollecting ? <CollectingSideBar><Teaser header={formContext.pageTeaserHeader} body={formContext.pageTeaserBodyText} /></CollectingSideBar> :
            <Teaser header={formContext.pageTeaserHeader} body={formContext.pageTeaserBodyText} />}
        </>}
      footer={formContext.footer}
    />
  </>;
};

const mapStateToProps = (state) => {
  return {
    isDialogVisible: state.reducer.isDialogVisible,
    formContext: state.reducer.formContext,
    isProductForm: state.reducer.isProductForm
  };
};

const mapDispatchToProps = (dispatch) => ({
  save: (history) => {
    dispatch(save(history));
  },
  gaPaymentFailure: () => {
    dispatch(gaPaymentFailure());
  },
  renderDialog: (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(renderDialog());
  },
  closeDialog: () => {
    dispatch(closeDialog());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTheme(Step3)));
