import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {withRouter} from 'react-router-dom';
import {withTheme} from 'styled-components';
import BigNumber from 'bignumber.js';
import LayoutContainer from '../../components/LayoutContainer';
import Title from '../../components/Title';
import Box from '../../components/Box';
import {Form, Field, FormValidation} from '@nexios/frontend-forms';
import Button from '../../components/Button';
import MainContainer from '../../components/MainContainer';
import Logo from '../../components/Logo';
import DisplayIban from '../../components/DisplayIban';
import ProgressBar from '../../components/ProgressBar';
import parseAndGetBigNumber from '../../utils/parseAndGetBigNumber';
import InputAmount from '../../components/InputAmount';
import FieldWrapper from '../../components/FieldWrapper';
import ErrorMessage from '../../components/ErrorMessage';
import {updateSession} from '../../reducer';
import {gaStep1} from '../../googleAnalytics/step1';
import Dropdown from '../../components/Dropdown';
import {Helmet} from 'react-helmet';
import parse from 'html-react-parser';
import replaceNewLinesForHtml from '../../utils/replaceNewLines';
import ProductImage from '../../components/ProductImage';
import useScrollToTop from '../../hooks/useScrollToTop';
import Teaser from '../../components/Teaser';

BigNumber.config({
  FORMAT: {
    decimalSeparator: ',',
    groupSeparator: '.',
    groupSize: 3
  }
});

const Product1 = ({formContext, save, history, gaStep1, next, progressBar, theme}) => {
  useScrollToTop();
  const formConfig = {
    onSubmit: () => {
      gaStep1(formContext);
      save(['onTopAmount_m', 'amount_m', 'productAmount', 'productTotalAmount_m', 'frequency'], () => {
        history.push(`${next}${history.location.search}`);
      });
    },
    onDataChanged: (ctx) => {
      const productAmount = _.get(ctx, ['data', 'productAmount']);
      const productDiscountPrice = !_.isNil(formContext.productDiscountPrice_m) ? parseAndGetBigNumber(formContext.productDiscountPrice_m) : parseAndGetBigNumber(formContext.productUnitPrice_m);
      const productUnitPrice = parseAndGetBigNumber(formContext.productUnitPrice_m);
      let shownOnTopAmount = parseAndGetBigNumber(_.get(ctx, ['data', 'onTopAmount_m'], '0.00'));
      const onTopAmountErrors = _.get(ctx, ['validation', 'onTopAmount_m'], []);
      // calculate with 0 if onTopAmount has errors
      if (!_.isEmpty(onTopAmountErrors)) {
        shownOnTopAmount = '0.00';
      }

      const productDiscountThreshold = _.toInteger(formContext.productDiscountThreshold_i);

      if (productAmount >= productDiscountThreshold) {
        ctx.setValues({
          productAmount,
          productTotalAmount_m: productDiscountPrice.times(parseAndGetBigNumber(productAmount)).toFixed(2),
          amount_m: productDiscountPrice.times(parseAndGetBigNumber(productAmount)).plus(shownOnTopAmount).toFixed(2),
          productUnitPrice_m: productDiscountPrice.toFixed(2)
        });
      } else {
        ctx.setValues({
          productAmount,
          productTotalAmount_m: productUnitPrice.times(parseAndGetBigNumber(productAmount)).toFixed(2),
          amount_m: productUnitPrice.times(parseAndGetBigNumber(productAmount)).plus(shownOnTopAmount).toFixed(2),
          productUnitPrice_m: productUnitPrice.toFixed(2)
        });
      }

      ctx.validate(true);
    },
    onInit: (ctx) => {
      const minimumProductQuantity = _.toInteger(formContext.minimumProductQuantity_i);
      const maximumProductQuantity = _.toInteger(formContext.maximumProductQuantity_i);
      const productQuantityStep = _.toInteger(formContext.productQuantityStep_i);
      const productDiscountThreshold = _.toInteger(formContext.productDiscountThreshold_i);
      const defaultProductQuantity = _.toInteger(formContext.defaultProductQuantity_i);

      const productAmountList = [];

      if (minimumProductQuantity === 1 && maximumProductQuantity === 1) {
        productAmountList.push({
          label: '1',
          value: '1'
        });
      } else {
        for (let i = minimumProductQuantity; i <= maximumProductQuantity; i += productQuantityStep) {
          productAmountList.push({
            label: `${i}`,
            value: `${i}`
          });
        }
      }

      ctx.setList('productAmount', productAmountList);

      const productDiscountPrice = !_.isNil(formContext.productDiscountPrice_m) ? parseAndGetBigNumber(formContext.productDiscountPrice_m) : parseAndGetBigNumber(formContext.productUnitPrice_m);

      const productUnitPrice = defaultProductQuantity >= productDiscountThreshold ? productDiscountPrice : parseAndGetBigNumber(formContext.productUnitPrice_m);

      ctx.setDefaultValues({
        productAmount: formContext.defaultProductQuantity_i,
        productTotalAmount_m: parseAndGetBigNumber(defaultProductQuantity).times(productUnitPrice).toFixed(2),
        amount_m: parseAndGetBigNumber(defaultProductQuantity).times(productUnitPrice).toFixed(2),
        productUnitPrice_m: productUnitPrice.toFixed(2),
        frequency: 'oneOff'
      });
    },
    fields: {
      amount_m: {
        isVisible: true,
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.amount_m) && ctx.amount_m !== ''
          }
        ]
      },
      productTotalAmount_m: {
        isVisible: true
      },
      onTopAmount: {
        isVisible: ctx => ctx.showOnTopAmount
      },
      onTopAmount_m: {
        isVisible: ctx => ctx.showOnTopAmount,
        rules: [
          {
            isValid: ctx => {
              if (_.isNil(ctx.maximumOnTopAmount_m) || ctx.onTopAmount_m === '') {
                return true;
              }

              const maximumOnTopAmount = parseAndGetBigNumber(_.get(ctx, ['maximumOnTopAmount_m'], '0'));
              const onTopAmount = parseAndGetBigNumber(_.get(ctx, ['onTopAmount_m'], '0'));

              if (onTopAmount.gt(maximumOnTopAmount)) {
                return false;
              }

              if (onTopAmount.lt(parseAndGetBigNumber(0))) {
                return false;
              }

              return true;
            },
            message: ctx => {
              const maximumOnTopAmount = parseAndGetBigNumber(_.get(ctx, ['maximumOnTopAmount_m']));

              return `Vul een bedrag in tussen € 0,00 en € ${maximumOnTopAmount.toFormat(2)}`;
            }
          }
        ]
      },
      variableFieldAnswer: {
        isVisible: ctx => ctx.isVariableFieldVisible && ctx.donationPageType === '10_donation',
        isRequired: ctx => ctx.isVariableFieldRequired,
        rules: [
          {
            isValid: ctx => {
              if (ctx.isVariableFieldRequired) {
                return !_.isNil(ctx.variableFieldAnswer) && _.trim(ctx.variableFieldAnswer) !== '';
              }

              return true;
            }
          }
        ]
      }
    }
  };

  return <>
    <Helmet>
      <title>{formContext.amountBrowserTitle}</title>
    </Helmet>
    <MainContainer
      backgroundImageUrl={formContext.backgroundImageUrl}
      backgroundImageTabletUrl={formContext.backgroundImageTabletUrl}
      backgroundImageMobileUrl={formContext.backgroundImageMobileUrl}
    />
    <LayoutContainer
      position={formContext.textPosition}
      header={<div>
        <div style={{float: 'left', display: 'inline-block'}}>
          <Logo url={formContext.websiteUrl} />
        </div>
        <DisplayIban iban='NL08 INGB 0000 0005 55' />
      </div>}
      content={<Box>
        <Title title={formContext.step1Header}><ProgressBar progress={progressBar} /></Title>
        <Form
          formInstanceKey='donate'
          formContext={formContext}
          formConfig={formConfig}>
          <FormValidation
            component={ErrorMessage}
            errorMessage='Je hebt nog niet alle velden ingevuld.' />
          <div className='widgetContent'>
            <div className='marginBottom'>{parse(replaceNewLinesForHtml(formContext.step1BodyText))}</div>

            <table className='productTable'>
              <tbody>
              <tr>
                <td>
                  {formContext.productImageUrl.endsWith('/undefined') ? '' : <ProductImage isSmall url={formContext.productImageUrl} />}
                </td>
                <td>
                  <div className='grey' style={{padding: '0 10px'}}><em>Aantal</em></div>
                </td>
                <td>
                  <Field
                    list='productAmount'
                    name='productAmount'
                    component={FieldWrapper}
                    inputComponent={Dropdown}
                    renderOneItemAsDiv={false}
                  />
                </td>
                <td className='alignRight'>
                  <Field
                    component={({value}) => {
                      const formattedValue = _.isNil(value) ? '0,00' : parseAndGetBigNumber(value).toFormat(2);
                      return <div className='textBox'>€ {formattedValue}</div>;
                    }}
                    name='productTotalAmount_m' />
                </td>
              </tr>
              <Field
                name='onTopAmount'
                component={() =>
                  <>
                    <tr>
                      <td colSpan={4}>
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>Extra gift <span className='grey'><em>(optioneel)</em></span></td>
                      <td>
                        <div style={{maxWidth: '150px'}}>
                          <Field
                            name='onTopAmount_m'
                            placeholder='0,00'
                            component={FieldWrapper}
                            inputComponent={InputAmount}
                          />
                        </div>
                      </td>
                    </tr>
                  </>
                } />
              <tr>
                <td colSpan={4}>
                  <hr />
                </td>
              </tr>
              <tr>
                <td colSpan={3}><span className='grey'><em>Verzending</em></span></td>
                <td className='alignRight'><span className='grey'><em>Gratis</em></span></td>
              </tr>
              <tr>
                <td colSpan={3}><strong>Totaalbedrag</strong></td>
                <td className='alignRight'>
                  <Field
                    component={({value}) => {
                      const formattedValue = _.isNil(value) ? '0,00' : parseAndGetBigNumber(value).toFormat(2);
                      return <div><strong>€ {formattedValue}</strong></div>;
                    }}
                    name='amount_m' />
                </td>
              </tr>
              </tbody>
            </table>

            <div className='marginBottom'>
              <FormValidation
                type='submit'
                label={formContext.amountCallToActionLabel || 'Naar gegevens'}
                component={Button}
              />
            </div>
            {formContext.entryCallToAction &&
              <div className='marginBottom centered'>
                {formContext.entryCallToActionUrl ? <a className='link' href={formContext.entryCallToActionUrl} rel='noopener noreferrer'>{formContext.entryCallToAction}</a> : formContext.entryCallToAction}
              </div>}
          </div>
        </Form>
      </Box>}
      sidebar={<>
        <ProductImage url={_.get(formContext, ['productImageUrl'])} collapseIfEmpty />
        <Teaser header={formContext.pageTeaserHeader} body={formContext.pageTeaserBodyText} />
      </>}
      faq={formContext.frequentlyAskedQuestions}
      footer={formContext.footer}
    />

  </>;
};

const mapStateToProps = (state) => {
  return {
    formContext: state.reducer.formContext
  };
};

const mapDispatchToProps = (dispatch) => ({
  save: (fields, callback) => {
    dispatch(updateSession(fields, {
      isStep1Completed: true
    }, callback));
  },
  gaStep1: (formContext) => {
    dispatch(gaStep1(formContext));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTheme(Product1)));
