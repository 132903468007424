import {css} from 'styled-components';
import logoAzn from '../icons/azn/logo-alzheimer.png';
import progress2 from '../icons/azn/step-indicator-2.svg';
import progress3 from '../icons/azn/step-indicator-3.svg';
import progress2of2 from '../icons/azn/step-indicator-2of2.svg';
import whatsapp from '../icons/sho/whatsapp.png';
import facebook from '../icons/sho/facebook.svg';
import linkedin from '../icons/sho/linkedin.svg';
import instagram from '../icons/sho/instagram.svg';
import twitter from '../icons/sho/x.svg';

const primaryColor = '#199A57';
const primary = '#FFD800';
const hover = '#FCF25F';
const errorColor = '#EC407A';

export default {
  primary,
  // Deze instellingen horen eigenlijk in fondsenwerving thuis, maar voor nu hardcoded in het theme
  hideProgressBarLead: true,
  showProductImageAboveTeaser: true,
  allowNewsletterDefault: true,
  hideWidgetHeaderTextInBody: true, // Wordt gebruikt op de eerste productpagina en leadpagina
  // step1WidgetHeaderText --> deze is in te stellen via fondsenwerving en wordt op alle pagina's getoond indien onderstaande instellingen niet in het theme staan.
  step2WidgetHeaderText: 'Stap 2: Mijn gegevens',
  step3WidgetHeaderText: 'Stap 3: Betaling',
  step3WidgetHeaderTextNoNaw: 'Stap 2: Betaling',
  // GlobalStyles
  bodyFontFamily: '"Open Sans", sans-serif',
  h1FontFamily: '"Raisonne AN DemiBold", "Open Sans", sans-serif',
  h3FontFamily: '"Raisonne AN DemiBold", "Open Sans", sans-serif',
  h1FontSize: '22px',
  bodyFontSize: '15px',
  background: css`
    background-image: ${props => `url('${props.backgroundImageUrl}')`};
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    position: fixed;
    top: -10px;
    left: -10px;
    bottom: -10px;
    right: -10px;
    z-index: -10;
    background-size: cover;

    @media (min-width: 768px) and (max-width: 1024px) {
      background-image: ${props => `url('${props.backgroundImageTabletUrl}')`};
    }

    @media (max-width: 767px) {
      background-image: ${props => `url('${props.backgroundImageMobileUrl}')`};
      //position: absolute;
      width: 100%;
      height: 100%;
      background-color: white;
      background-size: contain;
      background-position-y: top;
      background-position-x: left;
      background-repeat: no-repeat;
      inset: initial;
      z-index: -1;
    }
  `,
  // AmountButton
  amountButton: {
    color: primaryColor,
    fontFamily: '"Raisonne AN DemiBold", "Open Sans", sans-serif',
    fontSize: '17px',
    width: '94px',
    height: '56px',
    border: '1px solid #C4C4C4',
    borderRadius: '4px',
    backgroundColor: '#fff',
    backgroundColorActive: '#F3FAF6',
    borderHover: `1px solid ${primaryColor}`,
    boxShadowHover: `inset 0px 0px 0px 1px ${primaryColor}`
  },
  buttonContainerSpaceBetween: {
    width: '94px'
  },
  //BackButton
  backButton: {
    fontSize: '15px',
    color: '#1A1A1A'
  },
  // Box
  boxComponent: {
    borderRadius: '10px'
  },
  // Button
  button: {
    borderRadius: '5px',
    fontFamily: '"Raisonne AN DemiBold", "Open Sans", sans-serif',
    fontSize: '20px',
    height: '56px',
    backgroundColor: primary,
    color: '#000',
    boxShadow: 'none',
    active: css`
      background-color: ${hover};
      border-bottom: none;
      box-shadow: none !important;
    `,
    hover: css`
      background-color: ${hover};
    `,
    focus: css`
      box-shadow: none;
    `
  },
  // Checkbox
  inputCheckbox: {
    label: {
      color: '#565E60',
      fontSize: '14px'
    },
    border: '1px solid #C4C4C4',
  },
  box: {
    fontSize: '15px',
    colorHasError: errorColor,
    borderHasError: `1px solid ${errorColor}`,
    borderHover: '1px solid #818181',
    backgroundColorChecked: '#fff',
    backgroundBorderChecked: '1px solid #565E60',
    fillColorChecked: '#565E60'
  },
  // DateInput
  dateDropdown: {
    padding: '10px 30px 12.4px 10px',
    border: '1px solid #c4c4c4',
    boxShadowFocus: '0 0 0 3px #c4c4c4',
    borderFocus: '1px solid transparent',
    boxShadowError: '0 0 0 1px #E8141E',
    boxShadowValid: '0 0 0 1px #199A57'
  },
  // DisplayIban
  iban: {
    fontFamily: '"Raisonne AN DemiBold", "Open Sans", sans-serif',
    color: '#fff'
  },
  // Dropdown
  dropdown: {
    height: '46px',
    borderRadius: '4px',
    backgroundColor: '#fff',
    fontSize: '18px',
    paddingLeft: '13px',
    border: '1px solid #c4c4c4',
    borderFocus: '1px solid transparent',
    boxShadowFocus: '0 0 0 2px #818181'
  },
  // ErrorMessage
  error: {
    backgroundColor: '#F1F0E8',
    color: '#e8141e',
    height: '49px'
  },
  // Iban
  ibanContainer: {
    display: 'none'
  },
  // Input
  inputComponent: {
    fontSize: '15px',
    border: '1px solid #c4c4c4',
    borderFocus: `1px solid ${primaryColor}`,
    boxShadowFocus: `inset 0px 0px 0px 1px ${primaryColor}`,
    colorPlaceholder: '#c4c4c4',
    borderValid: '1px solid #199a57',
    boxShadowValid: 'none',
    backgroundColorValid: '#F3FAF6',
    backgroundColorFocus: '#F3FAF6',
    borderError: '1px solid #E8141E',
    boxShadowError: 'none',
    colorError: '#E8141E',
    backgroundColorError: '#FFE5E6'
  },
  // TextArea
  textAreaComponent: {
    fontSize: '15px',
    border: '1px solid #c4c4c4',
    borderFocus: `1px solid ${primaryColor}`,
    boxShadowFocus: `inset 0px 0px 0px 1px ${primaryColor}`,
    colorPlaceholder: '#c4c4c4',
    borderValid: '1px solid #199a57',
    boxShadowValid: 'none',
    backgroundColorValid: '#F3FAF6',
    borderError: '1px solid #E8141E',
    boxShadowError: 'none',
    colorError: '#E8141E',
    backgroundColorError: '#FFE5E6',
    rows: 1
  },
  // Label
  optionalLabel: {
    color: '#818181',
    display: 'none'
  },
  requiredLabel: {
    color: '#000',
    display: 'inline'
  },
  label: {
    fontSize: '15px',
    color: '#000',
    colorError: '#E8141E'
  },
  logo: {
    width: '210px',
    height: '180px',
    backgroundImage: logoAzn,
    widthMobile: '120px',
    heightMobile: '103px',
    margin: '0',
    marginMobile: '0'
  },
  // PaymentButton
  paymentButton: {
    color: primaryColor,
    fontFamily: '"Open Sans", sans-serif;',
    fontSize: '12px',
    width: 'calc(36% - 10px);',
    height: '78px',
    lineHeight: '78px',
    border: '1px solid #C4C4C4',
    borderRadius: '4px',
    backgroundColor: '#fff',
    backgroundColorActive: '#F3FAF6',
    borderHover: `1px solid ${primaryColor}`,
    boxShadowHover: `inset 0px 0px 0px 1px ${primaryColor}`
  },
  // RadioButton
  inputRadioButton: {
    backgroundColor: '#fff',
    border: '1px solid #c4c4c4',
    backgroundColorActive: '#fff',
    borderActive: '1px solid #c4c4c4',
    fontWeightActive: 'bold',
    fillColor: '#000',
    fillSize: 'large'
  },
  // ProgressBar
  progressBar: {
    step1: css`
      display: none;
    `,
    step2: css`
      width: 70px;
      background: url(${progress2}) no-repeat 0;
    `,
    step3: css`
      width: 70px;
      background: url(${progress3}) no-repeat 0;
    `,
    step1of2: css`
      display: none;
    `,
    step2of2: css`
      width: 40px;
      background: url(${progress2of2}) no-repeat 0;
    `
  },
  radio: {
    borderHover: '1px solid #818181',
    colorHasError: '#e8141e',
    borderHasError: '1px solid #e8141e',
    rightPadding: '26px',
    gap: '0.6rem'
  },
  // TeaserHeader
  teaser: {
    display: 'block',
    fontFamily: '"Raisonne AN DemiBold", "Open Sans", sans-serif',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '32px',
    fontSizeTablet: '18px',
    lineHeightTablet: '32px',
    fontSizeWide: '18px',
    lineHeightWide: '32px',
    subtitleFontFamily: '"Open Sans", sans-serif',
    subtitleFontSize: '15px',
    subtitleLineHeight: '25px',
    subtitleFontSizeWide: '15px',
    subtitleLineHeightWide: '25px',
    subtitleFontSizeTablet: '15px',
    subtitleLineHeightTablet: '25px',
    backgroundColor: '#F1F0E8',
    backgroundColorSubtitle: '#fff',
    color: '#000',
    colorSubtitle: '#000',
    padding: '3px 20px',
    paddingTopSubtitle: '0',
    topLeftTextAlignment: 'left',
    topRightTextAlignment: 'left',
    bottomLeftTextAlignment: 'left',
    bottomRightTextAlignment: 'left'
  },
  teaserContainer: {
    margin: '0'
  },
  // Title
  title: {
    color: '#000',
    fontFamily: '"Raisonne AN DemiBold", "Open Sans", sans-serif',
    fontSize: '18px',
    height: '60px',
    fontWeight: 'normal',
    borderRadius: '10px',
    backgroundColor: '#F1F0E8'
  },
  // Validation
  validation: {
    color: '#e8141e',
    fontSize: '15px'
  },
  // OptIn
  optInButton: {
    fontFamily: '"Raisonne AN DemiBold", "Open Sans", sans-serif',
    fontSize: '20px',
    backgroundColor: '#000',
    color: primary,
    boxShadow: 'none',
    active: css`
      background-color: #000;
      border-bottom: none;
      box-shadow: none !important;
    `,
    hover: css`
      background-color: #000;
    `,
    focus: css`
      box-shadow: none;
    `
  },
  // SocialShareButton
  socialShareButton: {
    backgroundSize: '50px',
    color: '#000',
    whatsapp: css`background-image: url(${whatsapp})`,
    facebook: css`background-image: url(${facebook})`,
    twitter: css`background-image: url(${twitter})`,
    linkedin: css`background-image: url(${linkedin})`,
    instagram: css`background-image: url(${instagram})`
  },
  smallHeader: true,
  linkHoverColor: '#EC407A'
};


