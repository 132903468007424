import _ from 'lodash';
import {is_gtm_loaded} from '../helpers';

export function gaRedirect(callback) {
  return () => {
    const redirectEvent = {
      'event': 'redirectOnPage',
      'redirectOnPage': 'redirect'
    };

    if (!is_gtm_loaded()) {
      if (_.isFunction(callback)) return callback();
      return;
    }

    if (_.isFunction(callback)) {
      redirectEvent.eventCallback = () => {
        callback();
      };
      redirectEvent.eventTimeout = 2000;

      // Final timeout if GTM does not work
      setTimeout(callback, 3000);
    }
    window.dataLayer.push(redirectEvent);
  };
}

export function gaNoRedirect() {
  return () => {
    window.dataLayer.push({
      'event': 'redirectOnPage',
      'redirectOnPage': 'no redirect'
    });
  };
}
